export function SafeGet(object, key) {
  // nosemgrep
  if( key === "constructor" && typeof object[key] === "function" ) {
    return ;
  }

  if( key === "__proto__" ) {
    return ;
  }

  // nosemgrep
  return object[key];
}

export function SafeSet(object, key, value) {
  // nosemgrep
  if( key === "constructor" && typeof object[key] === "function" ) {
    return ;
  }

  if( key === "__proto__" ) {
    return ;
  }

  // nosemgrep
  object[key] = value;
  return object;
}
